<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        sync: true,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Place type', value: 'placeType' },
          {
            text: 'Space count',
            value: 'count.spaces',
            component: {
              name: 'dt-view',
              props: { permission: 'space', getter: item => this.$toList('space', { placeId: item.slug }) },
            },
          },
          {
            text: 'Location count',
            value: 'count.locations',
            component: {
              name: 'dt-view',
              props: { permission: 'location', getter: item => this.$toList('location', { placeId: item.slug }) },
            },
          },
        ],
        hideAction: true,
      },
    };
  },
};
</script>
